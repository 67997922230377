import React from 'react';
import colors from './colours.json';
import { CssBaseline, Grid, Typography, Box, Paper } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark', // Apply dark mode globally
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: '#2D2D2D',
          },
        },
      },
    },
  },
});

function App() {
  const sortedColors = colors.sort((a, b) => a.HEX.localeCompare(b.HEX));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        {/* 60% Section Placeholder */}
        <Box sx={{ width: '60%' }}>
          {/* Future content */}
        </Box>

        {/* 40% Section for the list, now with a background and a sleek outline */}
        <Box sx={{
          width: '40%',
          overflowY: 'auto',
          maxHeight: '100vh',
          p: '20px 0',
          backgroundColor: '#1A1A1A', // Slightly different from the overall page background
          borderRight: '1px solid #333333', // Thin outline
        }}>
          <Grid container direction="column">
            {sortedColors.map((color, index) => (
              <Grid item key={index} sx={{ pb: 0 }}>
                <Paper sx={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px', margin: '0px', ':hover': { backgroundColor: '#2D2D2D' }, transition: 'background-color 0.3s' }} elevation={0}>
                  <Typography variant="body2">
                    {color.NAME}
                  </Typography>
                  <Typography variant="body2">
                    {color.HEX}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
